import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { Employees } from "routes/manufacturingNew/Employees";
import { MdiBadge } from "components/miloDesignSystem/atoms/icons/MdiBadge";

export const manufacturingEmployees: ModuleLink = {
  url: "employees",
  label: "Pracownicy",
  icon: MdiBadge,
  routing: Employees,
};
