import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { SoldIndexesRouting } from "routes/finances/SoldIndexes";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";

export const soldIndexes: ModuleLink = {
  url: "sold-indexes",
  label: "Sprzedane indeksy",
  icon: MdiChair,
  routing: SoldIndexesRouting,
};
