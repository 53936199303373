import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { Dashboard } from "routes/reports/Dashboard";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";

export const products: ModuleLink = {
  url: "products",
  label: "Produkty",
  icon: MdiChair,
  routing: Dashboard,
  isAccessible: false,
};
