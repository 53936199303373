import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { Dashboard } from "routes/humanResources/Dashboard";
import { MdiBadge } from "components/miloDesignSystem/atoms/icons/MdiBadge";

export const employees: ModuleLink = {
  url: "employees",
  label: "Pracownicy",
  icon: MdiBadge,
  routing: Dashboard,
  isAccessible: false,
};
