import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { ProductsRouting } from "routes/wms/ProductsRouting";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";

export const products: ModuleLink = {
  url: "products",
  label: "Produkty",
  icon: MdiChair,
  routing: ProductsRouting,
};
