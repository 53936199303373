import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { StateLabel } from "components/common/stateLabel";
import rejectionIcon from "assets/images/call_missed.svg";

interface Props {
  order: CallCenterRouteOrderListItem;
}

export const RejectionStatus = ({ order }: Props) => {
  if (order.isDeliveryDateRejected) {
    return (
      <div>
        <StateLabel kind="deepOrange4">
          <img alt="potwierdzone" src={rejectionIcon} style={{ height: "16px", width: "16px" }} />
          <span className="fw-700">Odrzucono</span>
        </StateLabel>
      </div>
    );
  }

  return <div className="fw-700">---</div>;
};
