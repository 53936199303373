import { AddLineItemToUnloadingPayload, UnloadingDetails } from "api/wms/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./AddIndexToUnloadingModal.module.css";
import { useSelector } from "hooks";
import { Autocomplete, AutocompleteAsyncHandler } from "components/utils";
import { ProductLight } from "api/products/models";
import { SearchItem } from "pages/orders/shared/rightPanel/productsSection/productForm/SearchProduct";
import { getProductsLight } from "api/products/calls";
import { useState } from "react";
import { useAddIndexToUnloading } from "api/wms/hooks";
import { ProductForm } from "pages/orders/shared/rightPanel/productsSection/productForm";
import { Select } from "components/miloDesignSystem/molecules/select";
import { SelectProps } from "components/miloDesignSystem/molecules/select/types";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

interface Props {
  close: () => void;
  unloading: UnloadingDetails;
}

export const AddIndexToUnloadingModal = ({ close, unloading }: Props) => {
  const customers = useSelector(store => store.partials.customers);

  const customersOptions: SelectProps["items"] = customers.map(customer => ({
    type: MenuItemType.TEXT,
    value: customer.id,
    text: customer.name,
  }));
  const addIndexToUnloadingMutation = useAddIndexToUnloading(close);

  const [selectedCustomer, setSelectedCustomer] = useState(customersOptions[0].value);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj mebel
        </Typography>
      }
      width={741}
    >
      <div className="d-flex flex-column p-3">
        <Select
          items={customersOptions}
          selected={selectedCustomer}
          onChange={item => setSelectedCustomer(item as number)}
          label="Kontrahent"
          textFieldProps={{
            size: "default",
          }}
        />

        <ProductForm
          submitCreateProduct={values => {
            addIndexToUnloadingMutation.mutate({
              customerId: selectedCustomer,
              id: unloading.id,
              indexId: values.productElements[0].index,
              quantity: values.productElements[0].quantity,
              unloadingId: unloading.id,
            } as AddLineItemToUnloadingPayload);
          }}
          overrides={{
            removeHorizontalPadding: true,
            showAmountPicker: false,
            addButtonText: "Dodaj do rozładunku",
            CustomSearchProductComponent: ({ onChange }) => (
              <AutocompleteAsyncHandler
                transform={(item: ProductLight) => {
                  return {
                    ...item,
                    name: <SearchItem item={item} />,
                  };
                }}
                transformQuery={query => ({
                  ...query,
                  pageSize: "999",
                  customer: String(selectedCustomer),
                })}
                fetchFrom={getProductsLight}
              >
                <Autocomplete
                  placeholder="Szukaj produktu"
                  overrides={{
                    selectedItem: { className: styles.searchItem },
                    searchBar: { className: styles.searchBar },
                  }}
                  onChange={products => {
                    onChange(products[0] || null);
                    setSelectedCustomer(selectedCustomer);
                  }}
                  multiple={false}
                />
              </AutocompleteAsyncHandler>
            ),
          }}
          order={{ currency: "PLN", customer: null, salesAccount: { currency: "PLN" } }}
        />
      </div>
    </Modal>
  );
};
