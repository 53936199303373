import { Decimal } from "api/types";

export const getAmountAfterDiscount = ({
  amount,
  customerDiscount,
}: {
  amount: Decimal;
  customerDiscount: number | null;
}): Decimal => {
  const discount = Number(customerDiscount) || 0;
  const discountedAmount = Number(amount) - (Number(amount) * discount) / 100;
  return discountedAmount.toFixed(2);
};
