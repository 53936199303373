import productPlaceholderPhoto from "assets/images/53.svg";
import pencilImg from "assets/images/1.svg";
import { useEffect, useMemo, useState } from "react";
import { FormInput, Counter, FormTextarea, Toggle, StatusHandler } from "components/utils";
import { Button } from "components/common";
import styles from "./ProductForm.module.css";
import productStyles from "../ProductRow/ProductRow.module.css";
import cx from "classnames";
import { postIndex } from "api/indexes/calls";
import { useToastr, useStateModal } from "hooks";
import { useAlertModal } from "hooks/useAlertModal";
import { Link } from "react-router-dom";
import { AttributeSection } from "./attributeSection/AttributeSection";
import { FLAVOR } from "CONSTANTS";
import { AssignPackageModal } from "components/common/assignPackagesModal";
import { Product } from "api/products/models";
import { getAnyErrorKey, queryString } from "utilities";
import cuid from "cuid";
import { findSelectedAttributeValue } from "utilities/findSelectedAttributeValue";
import { AdaptedOrder, AdaptedProduct, ProductFormValues } from "./ProductForm";
import { indexesActions } from "api/indexes/actions";
import { useQueryClient } from "react-query";
import { indexesKeys } from "api/indexes/keys";
import { getProductIndexBasedOnAttributesState } from "./utils/getProductIndexBasedOnAttributesState";
import { productsActions } from "api/products/actions";
import { productsKeys } from "api/products/keys";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { getAmountAfterDiscount } from "./utils/getAmountAfterDiscount";

interface Props {
  product: Product | null;
  order: AdaptedOrder;
  formType: "edit" | "create";
  productToFetch: any;
  values: ProductFormValues["productElements"][number];
  setFieldValue: (name: keyof ProductFormValues["productElements"][number], value: any) => void;
  valueIndex: number;
  changeAttribute: (
    attributeId: number,
    value: number | null,
    cuid: string,
    productIndexes: Record<string, number>,
  ) => void;
  showQuantityPicker: boolean;
  showAmountPicker: boolean;
  isTogglingEnable: boolean;
}

export const SingleProductForm = ({
  product,
  order,
  formType,
  productToFetch,
  setFieldValue,
  values,
  valueIndex,
  changeAttribute,
  showAmountPicker,
  showQuantityPicker,
  isTogglingEnable,
}: Props) => {
  const modal = useStateModal<{ indexId: number }>();
  const [isEditingProductSetProduct, setIsEditingProductSetProduct] = useState(true);
  const selectedAttributes = values.attributesState
    .map(attr => attr.valueId)
    .filter(Boolean) as number[];
  const areAllAttributesSelected = selectedAttributes.length === values.attributesState.length;
  const { data: indexes } = productsActions.useGetProductIndexes(
    queryString.stringify({
      products: [product!.id],
      customer:
        FLAVOR === "b2b" && order.customer && !order?.customer.canAddNewIndexes
          ? order?.customer!.id
          : "",
    }),
    {
      enabled: Boolean(product),
    },
  );

  const { data: indexExist, isFetched } = indexesActions.useIndexExist(
    queryString.stringify({
      values: selectedAttributes,
      product: product!.id,
    }),
    {
      enabled: areAllAttributesSelected,
    },
  );
  const { data: indexesPrices } = productsActions.useGetProductIndexesPrice(
    queryString.stringify({
      products: [product!.id],
      priceList: order.customer?.priceList?.id || "",
    }),
    {
      enabled: Boolean(product && order.customer?.priceList),
    },
  );

  const attributesToDisplay = useMemo(
    () => product?.attributes.map(attribute => ({ ...attribute, cuid: cuid() })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const index = getProductIndexBasedOnAttributesState(indexes, values.attributesState);
    const amount = index ? String(indexesPrices?.[index] || 0) : 0;
    setFieldValue("index", index);
    setFieldValue("amount", amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexes, values.attributesState]);

  if (!isEditingProductSetProduct) {
    return (
      <ProductSetProductItem
        order={order}
        product={product}
        toggleIsEditingProductSetProduct={() => setIsEditingProductSetProduct(p => !p)}
        values={values}
      />
    );
  }

  return (
    <div className={styles.section}>
      <div className={cx(styles.miniature, "mt-2 mb-2 d-flex align-items-center")}>
        <img src={product?.picture || productPlaceholderPhoto} alt="" />
        <div className="ml-1 d-flex align-items-center">
          <div className="mr-2">{values.name}</div>
          {formType === "create" &&
            (productToFetch.amount ? (
              <small>
                ({productToFetch.amount} <span>{order.currency}</span>)
              </small>
            ) : null)}
        </div>
      </div>

      <AttributeSection
        productsIds={[product!.id]}
        attributesState={values.attributesState}
        order={order}
        attributesToDisplay={attributesToDisplay || []}
        changeAttribute={(attributeId, value, attributesState, productIndexes) =>
          product && changeAttribute(attributeId, value, values.cuid, productIndexes)
        }
      />
      <AmountForItem
        setFieldValue={setFieldValue}
        formType={formType}
        order={order}
        showAmountPicker={showAmountPicker}
        valueIndex={valueIndex}
        values={values}
      />
      {showQuantityPicker && (
        <div className={cx("mb-4", styles.formSection)}>
          <h5>Liczba</h5>
          <Counter
            disabled={Boolean(values.productSetCode)}
            onChange={val => setFieldValue("quantity", val)}
            value={values.quantity}
          />
        </div>
      )}
      {product?.attributes.length !== 0 &&
        (FLAVOR === "main" || order.customer?.canAddNewIndexes) && (
          <div>
            {!indexExist && isFetched && areAllAttributesSelected && (
              <IndexNotExist
                product={product}
                openAssignPackagesModal={indexId => modal.open({ indexId })}
                values={values}
              />
            )}
          </div>
        )}

      <div className={cx(styles.formSection, "mt-2")}>
        <Toggle initialValue={Boolean(values.note)}>
          {({ isOpen, toggle }) => (
            <div>
              {isOpen && (
                <div className={cx("d-flex align-items-center", styles.inputBox)}>
                  <FormTextarea
                    name={`productElements[${valueIndex}].note`}
                    label="Uwagi"
                    className="w-100"
                  />
                </div>
              )}
              <div className="d-flex justify-content-between">
                {!values.productSetCode && <div></div>}
                <Button kind="secondary-stroke" onClick={toggle} size="small">
                  {isOpen ? "Ukryj" : "Dodaj"} uwagi
                </Button>
                {values.productSetCode && isTogglingEnable && (
                  <Button
                    kind="primary"
                    onClick={() => setIsEditingProductSetProduct(false)}
                    size="small"
                  >
                    Gotowe
                  </Button>
                )}
              </div>
            </div>
          )}
        </Toggle>
      </div>
      {modal.isOpen && <AssignPackageModal modal={modal} />}
    </div>
  );
};

const AmountForItem = ({
  order,
  values,
  showAmountPicker,
  valueIndex,
  formType,
  setFieldValue,
}: {
  showAmountPicker: boolean;
  order: AdaptedOrder;
  values: ProductFormValues["productElements"][number];
  valueIndex: number;
  formType: "edit" | "create";
  setFieldValue: (name: keyof ProductFormValues["productElements"][number], value: any) => void;
}) => {
  if (!showAmountPicker) return null;

  const readOnly = FLAVOR === "b2b" && !order.customer?.hasDropShipping;
  const shouldDiscountBeVisible = formType === "create" && Number(order.customer?.discount) > 0;

  return (
    <div className={styles.formSection}>
      <h5>Cena za sztukę</h5>

      <div className={cx("d-flex align-items-start gap-2 mb-2", styles.inputBox)}>
        {readOnly ? (
          <div className={styles.readyOnlyInput}>
            <Typography fontSize="16" fontWeight="600" color="neutralBlack64">
              {values.amount}
            </Typography>
          </div>
        ) : (
          <FormInput
            name={`productElements[${valueIndex}].amount`}
            type="number"
            className="mr-1"
            overwrites={{ wrapper: { className: "mb-0" } }}
          />
        )}
        {values.shouldIncludeCustomerDiscountAmount && shouldDiscountBeVisible && (
          <div>
            <Typography fontSize="10" fontWeight="600">
              cena z rabatem:
            </Typography>
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="12" fontWeight="700">
                {getAmountAfterDiscount({
                  amount: values.amount,
                  customerDiscount: order.customer?.discount || null,
                })}{" "}
                {values.currency}
              </Typography>
              <Typography
                fontSize="10"
                fontWeight="400"
                color="neutralBlack48"
                className="line-through"
              >
                {Number(values.amount).toFixed(2)} {values.currency}
              </Typography>
            </div>
          </div>
        )}
      </div>
      {shouldDiscountBeVisible && (
        <Checkbox
          disabled={readOnly}
          checked={values.shouldIncludeCustomerDiscountAmount}
          label={`Uwzględniaj stały rabat ${order.customer?.discount || 0}%`}
          onChange={status => {
            setFieldValue("shouldIncludeCustomerDiscountAmount", status);
          }}
          size="sm"
        />
      )}
    </div>
  );
};

const ProductSetProductItem = ({
  order,
  values,
  toggleIsEditingProductSetProduct,
  product,
}: {
  order: Props["order"];
  values: Props["values"];
  product: Props["product"];
  toggleIsEditingProductSetProduct: () => void;
}) => {
  return (
    <div
      className={cx("w-100  cursor-pointer", productStyles.purpleBorder, styles.section)}
      onClick={toggleIsEditingProductSetProduct}
    >
      <div>
        <div
          className={cx(
            "d-flex align-items-center justify-content-between",
            productStyles.productRow,
          )}
        >
          <div className={productStyles.product}>
            <div className="my-auto">
              <div className="d-flex align-items-center">
                <div className={productStyles.productName}>{values.name}</div>
              </div>
              <div className="d-flex mt-1 flex-wrap">
                {values.attributesState.every(el => el.valueId) ? (
                  product?.attributes.map(attribute => {
                    const value = findSelectedAttributeValue.byArrayOfValues(
                      attribute,
                      values.attributesState.map(el => el.valueId!),
                    );
                    if (!value) return null;
                    return (
                      <div className={cx("mr-1", productStyles.versionBox)} key={attribute.id}>
                        <span className={productStyles.label} title={attribute.name}>
                          {value.name}
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <span className={productStyles.noAttributes}>Wybierz cechy produktu</span>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <div className="position-relative mr-4">{values.quantity}szt</div>
              <div className="mr-2">
                <div className="d-flex justify-content-end align-items-baseline">
                  <div className={cx(productStyles.price, "mr-1")}>{values.amount} </div>
                  <div>{order.currency}</div>
                </div>
                <div className={productStyles.weight}>{product?.weight}kg/szt.</div>
              </div>
            </div>
          </div>

          <Button type="button" kind="secondary" size="round-s">
            <img src={pencilImg} alt="Usuń produkt z zamówienia" />
          </Button>
        </div>

        <div className="d-flex align-items-center">
          <span className="mr-1" style={{ fontSize: 14 }}>
            Uwagi:{" "}
          </span>
          {values.note ? values.note : <small className="text-color-grey">Brak uwag</small>}
        </div>
      </div>
    </div>
  );
};

const IndexNotExist = ({
  values,
  openAssignPackagesModal,
  product,
}: {
  values: Props["values"];
  openAssignPackagesModal: (indexId: number) => void;
  product: Props["product"];
}) => {
  const alertModal = useAlertModal();
  const toastr = useToastr();
  const queryClient = useQueryClient();

  async function handlePostIndex(
    productId: AdaptedProduct["id"],
    setFetching: (val: boolean) => void,
  ) {
    if (values.attributesState.length === 0) {
      alertModal.open(
        <div className="pb-2">
          <h5 className="pb-3">Nie można utworzyć wariantu</h5>
          Produkt nie ma cech.{" "}
          <Link to={`/products/${productId}/update`} onClick={alertModal.close}>
            Edytuj produkt i dodaj cechy
          </Link>
          .
        </div>,
      );
      return;
    }
    setFetching(true);

    const [payload, error] = await postIndex({
      product: productId,
      attributesValues: values.attributesState.map(attr => ({
        attribute: attr.attributeId,
        value: attr.valueId,
      })),
    });

    setFetching(false);

    queryClient.invalidateQueries(indexesKeys.indexExist());
    queryClient.invalidateQueries(productsKeys.productIndexes());

    if (payload) {
      openAssignPackagesModal(payload.id);
    } else if (error) {
      toastr.open({
        type: "warning",
        title: "Oj, coś nie tak...",
        text: getAnyErrorKey(error),
      });
    }
  }
  return (
    <div className={cx("d-flex align-items-center justify-content-between mt-3 mb-4")}>
      <div className="text-color-coral mr-3">
        Wybrany wariant nie istnieje. Czy chcesz abyśmy go utworzyli?
      </div>
      <StatusHandler>
        {({ isFetching, setFetching }) => (
          <Button
            kind="primary"
            type="button"
            size="small"
            disabled={isFetching}
            onClick={() => handlePostIndex(product!.id, setFetching)}
          >
            <span>Tak, utwórz</span>
          </Button>
        )}
      </StatusHandler>
    </div>
  );
};
