import { DayToPick as DayToPickInterface, DayType } from "../../AddWorkingDayModal";
import localStyles from "./DayToPick.module.css";
import cx from "classnames";
import { MdiCheckCircle2 } from "components/miloDesignSystem/atoms/icons/MdiCheckCircle2";

interface Props {
  day: DayToPickInterface;
  isDateAlreadyInPlan: (date: string) => boolean;
  kind: DayType;
  pickedDates: string[];
  setPickedDates: React.Dispatch<React.SetStateAction<string[]>>;
  togglePickedDate: (date: string, kind: DayType) => void;
}

export const DayToPick = ({
  day,
  isDateAlreadyInPlan,
  kind,
  pickedDates,
  setPickedDates,
  togglePickedDate,
}: Props) => {
  return (
    <div
      className={cx("mb-2", {
        [localStyles.previousDayContainer]: kind === "PREVIOUS",
        [localStyles.nextDayContainer]: kind === "NEXT",
        [localStyles.previousDayActive]:
          kind === "PREVIOUS" && day.picked && !isDateAlreadyInPlan(day.date),
        [localStyles.nextDayActive]:
          kind === "NEXT" && day.picked && !isDateAlreadyInPlan(day.date),
        [localStyles.previousDayInPlan]: kind === "PREVIOUS" && isDateAlreadyInPlan(day.date),
        [localStyles.nextDayInPlan]: kind === "NEXT" && isDateAlreadyInPlan(day.date),
      })}
      onClick={() => {
        if (!isDateAlreadyInPlan(day.date)) {
          togglePickedDate(day.date, kind);
          const isPicked = pickedDates.find(el => el === day.date);
          if (!isPicked) {
            setPickedDates(pickedDates => {
              return [...pickedDates, day.date];
            });
          } else {
            setPickedDates(pickedDates => {
              const updatedPickedDates = pickedDates.filter(date => date !== day.date);
              return updatedPickedDates;
            });
          }
        }
      }}
    >
      <div className="d-flex align-items-center justify-content-center">
        {day.name.length > 0 ? (
          <>
            {kind === "PREVIOUS" && isDateAlreadyInPlan(day.date) && (
              <MdiCheckCircle2 className="mr-1" color="red400" size="22" />
            )}
            {kind === "NEXT" && isDateAlreadyInPlan(day.date) && (
              <MdiCheckCircle2 className="mr-1" color="deepPurple700" size="22" />
            )}
            <span>
              {day.day} ({day.name})
            </span>
          </>
        ) : (
          <>
            {kind === "PREVIOUS" && isDateAlreadyInPlan(day.date) && (
              <MdiCheckCircle2 className="mr-1" color="red400" size="22" />
            )}
            {kind === "NEXT" && isDateAlreadyInPlan(day.date) && (
              <MdiCheckCircle2 className="mr-1" color="deepPurple700" size="22" />
            )}
            <span>{day.day}</span>
          </>
        )}
      </div>
      <div className="d-flex justify-content-center fs-14">
        <span>{day.date}</span>
      </div>
    </div>
  );
};
