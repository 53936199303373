/**
 * example: 112-12-31-11
 */
type IndexValuePair = string;
type IndexId = number;
type ProductIndexes = Record<IndexValuePair, IndexId>;
/**
 * @returns possible combination of attributes values from created indexes
 * @example:
 * 1. Create an order for the customer with a product set.
 * 2. The product set includes product X.
 * 3. Product X contains 30 indexes in total.
 * 4. The customer adds only the first 10 indexes.
 * 5. This function returns only the indexes that can still be added from the product set.
 */

export function getIndexPossibleAttributeValues({
  productIndexes,
  selectedIndexes,
}: {
  productIndexes: ProductIndexes | null;
  /**
   * If user selects some attributes values we have to make sure that rest of them creates proper index
   */
  selectedIndexes: number[];
}) {
  const indexesValuePairs = Object.keys(productIndexes || {});

  const possibleAttributesValues: string[][] = [];

  indexesValuePairs.forEach(index => {
    const indexArray = index.split("-");

    if (selectedIndexes.every(addedIndex => indexArray.includes(String(addedIndex)))) {
      possibleAttributesValues.push(indexArray);
    }
  });

  return [...new Set(possibleAttributesValues.flat())].map(Number);
}
