import distanceIcon from "assets/images/distance.svg";
import timeIcon from "assets/images/46.svg";
import { PinToRoute } from "api/routes/models";
import { UnpinnedLog } from "./UnpinnedLog";
import { MdiAccountCircle } from "components/miloDesignSystem/atoms/icons/MdiAccountCircle";

interface Props {
  unpinnedLogs: PinToRoute[];
}

export const UnpinnedOrderInfo = ({ unpinnedLogs }: Props) => {
  return (
    <div className="d-flex flex-wrap m-0 row border-top pt-2 pb-2">
      <div className="d-flex align-items-center text-black-6 fs-12 p-1 w-100 border-bottom">
        <div className="col-4 d-flex align-items-center gap-1 p-0">
          <img src={distanceIcon} alt="Trasa" style={{ height: "16px", width: "16px" }} />
          trasa
        </div>
        <div className="col-4 d-flex align-items-center gap-1 p-0">
          <img src={timeIcon} alt="Kiedy odpięto" style={{ height: "16px", width: "16px" }} />
          data odpięcia
        </div>
        <div className="col-4 d-flex align-items-center gap-1 p-0">
          <MdiAccountCircle size="16" />
          odpięte przez
        </div>
      </div>
      {unpinnedLogs.map(unpinnedLog => (
        <UnpinnedLog key={unpinnedLog.id} unpinnedLog={unpinnedLog} />
      ))}
    </div>
  );
};
