import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiDescription } from "components/miloDesignSystem/atoms/icons/MdiDescription";
import { tradingDocumentsTabDict } from "pages/tradingDocuments/shared/utils/getTabs";
import { PurchaseInvoices } from "routes/PurchaseInvoices";

export const purchaseInvoices: ModuleLink = {
  url: "purchases",
  label: "Faktury Zakupowe",
  icon: MdiDescription,
  subSection: {
    renderer: <TabSubSection baseUrl="/finances/purchases/list" tabs={tradingDocumentsTabDict} />,
    isExpandedByDefault: false,
  },
  routing: PurchaseInvoices,
};
