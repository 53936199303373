import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { Products } from "routes/inventory/Products";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";

export const products: ModuleLink = {
  url: "products",
  label: "Produkty",
  icon: MdiChair,
  routing: Products,
};
