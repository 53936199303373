import apiFetch, { tokenRefreshMiddleware } from "../../apiConnectors/fetchConnector";
import {
  Category,
  Product,
  ProductComponent,
  ProductLight,
  ProductEntity,
  PostPackage,
  PostPackagePreview,
  RightPanelProductAttribute,
  GeneratePackagesPayload,
  ProductLightWithDeleted,
} from "./models";
import { Pagination } from "../types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { Attribute } from "api/attributes/models";
import { queryString } from "utilities";
import { parsePatchData } from "utilities/parsePatchData";
import { PartialOf } from "typeUtilities";
import { fileFactoryUtils } from "utilities/fileFactory";
import { productsKeys } from "./keys";

const api = tokenRefreshMiddleware(apiFetch);

export const getProducts = (search: string = "", abortToken?: string) =>
  api<Pagination<Product>>({
    method: "GET",
    url: "/products/items" + search,
    abortToken,
  });

export const getProductsQuery = (search: string = "") => ({
  key: productsKeys.productAttributesFilter.list(search),
  fetcher: () =>
    queryFetch<Pagination<Product>>({
      method: "GET",
      url: "/products/items" + search,
    }),
});

export const getProductsLight = (search: string = "", abortToken?: string) =>
  api<Pagination<ProductLight>>({
    method: "GET",
    url: "/products/light" + search,
    abortToken,
  });

export const getProductsLightWithDeleted = (search: string = "", abortToken?: string) =>
  api<Pagination<ProductLightWithDeleted>>({
    method: "GET",
    url: "/products/light-with-deleted" + search,
    abortToken,
  });

export const getProductsLightWithDeletedQuery = (search: string = "") => ({
  key: productsKeys.productsWithDeleted.list(search),
  fetcher: () =>
    queryFetch<Pagination<ProductLightWithDeleted>>({
      method: "GET",
      url: "/products/light-with-deleted" + search,
    }),
});

export const getProductsLightQuery = (search: string = "") => ({
  key: productsKeys.products.list(search),
  fetcher: () =>
    queryFetch<Pagination<ProductLight>>({
      method: "GET",
      url: "/products/light" + search,
    }),
});

export const getProduct = (id: number | string) =>
  api<Product>({
    method: "GET",
    url: "/products/items/" + id,
  });

export const patchProductQuery = ({
  id,
  toUpdate,
}: {
  toUpdate: PartialOf<Product>;
  id: Product["id"];
}) =>
  queryFetch<Product>({
    method: "PATCH",
    url: "/products/items/" + id,
    data: parsePatchData(toUpdate),
  });

export const postProductsCsv = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/products/v2/import",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const getProductAttributesQuery = (
  search: string = "",
): ApiFetcher<Pagination<RightPanelProductAttribute>> => ({
  key: productsKeys.productAttributes.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/product-attributes" + search,
    }),
});

export const patchProductAttributesQuery = (
  id: number,
  data: Partial<RightPanelProductAttribute>,
) =>
  queryFetch<RightPanelProductAttribute>({
    method: "PATCH",
    url: "/products/product-attributes/" + id,
    data,
  });

export const postProduct = (data: FormData | object) =>
  api<Product>({
    method: "POST",
    url: "/products/items",
    data,
  });

export const putProduct = (data: FormData, id: string | number) =>
  api<Product>({
    method: "PUT",
    url: "/products/items/" + id,
    data,
  });

export const patchProduct = (id: string | number, data: Partial<Omit<Product, "id">>) =>
  api<Product>({
    method: "PATCH",
    url: "/products/items/" + id,
    data,
  });

export const restoreProduct = (id: string | number) =>
  queryFetch<void>({
    method: "PATCH",
    url: `/products/items/${id}/restore`,
  });

export const postGeneratePackagesForProductIndexes = (data: GeneratePackagesPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/packages/create-from-indexes",
    data,
  });

export const postCsvWithProducts = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/products/add-csv-products-by-b2b",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.csv"',
    },
    onUploadProgress,
  });

export const postCategory = (data: { name: string }) =>
  api<{ name: string }>({
    method: "POST",
    url: "/products/categories",
    data,
  });

export const putCategory = (id: number | string, data: { name: string }) =>
  api<{ name: string }>({
    method: "PUT",
    url: "/products/categories/" + id,
    data,
  });

export const getCategories = (search: string = "", abortToken?: string) =>
  api<Pagination<Category>>({
    method: "GET",
    url: "/products/categories" + search,
    abortToken,
  });

export const patchCategory = (
  configurationId: number | null,
  ids: number[] | string[],
  action: "add" | "remove",
) =>
  queryFetch<void>({
    method: "PATCH",
    url: `/owners/configurations/${configurationId}/category-to-count`,
    data: {
      ids,
      action,
    },
  });

export const deleteProduct = (productId: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/products/items/" + productId,
  });

export const postSetComponent = (data: { mainProduct: number; element: number }) =>
  api<ProductComponent>({
    method: "POST",
    url: "/products/components",
    data,
  });

export const deleteSetComponent = (itemId: number) =>
  api<void>({
    method: "DELETE",
    url: "/products/components/" + itemId,
  });

export const patchSetComponent = (
  id: string | number,
  data: Partial<Omit<ProductComponent, "id">>,
) =>
  api<ProductComponent>({
    method: "PATCH",
    url: "/products/components/" + id,
    data,
  });

export const getProductAttributes = (
  id: string | number,
): ApiFetcher<{
  id: number;
  attributes: Attribute[];
}> => ({
  key: productsKeys.attributesPerProduct(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/attributes/" + id,
    }),
});

export const getProductCategories = (search: string = ""): ApiFetcher<Pagination<Category>> => ({
  key: productsKeys.categories.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/categories" + search,
    }),
});

export const getProductEntity = (id: number | string) =>
  api<ProductEntity>({
    method: "GET",
    url: "/products/separated/" + id,
  });

export const getProductsForCustomer = (search: string = "", abortToken?: string) =>
  api<Pagination<ProductLight>>({
    method: "GET",
    url: "/products/for-customer" + search,
    abortToken,
  });

export const postProductForCustomer = (data: FormData | object) =>
  api<void>({
    method: "POST",
    url: "/products/add-products-by-b2b",
    data,
  });

export const postPackage = (data: PostPackage) =>
  queryFetch<PostPackagePreview>({
    method: "POST",
    url: "/packages/for-customer",
    data,
  });

export const patchCustomMade = (args: {
  key: "product" | "index" | "package";
  data: { isCustomMade: boolean };
  entry: string | number;
}) =>
  queryFetch<void>({
    method: "PATCH",
    url:
      "/packages/bulk-update-custom-made" +
      queryString.stringify({ [args.key === "package" ? "id" : args.key]: args.entry }),
    data: args.data,
  });

export const deletePackage = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/packages/for-customer/" + id,
  });

export const postCsvWithPackages = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/packages/add-csv-packages-by-b2b",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.csv"',
    },
    onUploadProgress,
  });

export const productFileFactory = (() => {
  return {
    indexesIds: () => ({
      url: `/products/for-customer/get-csv-with-indexes-ids`,
      name: `indeksy_z_id_${fileFactoryUtils.getDate()}`,
    }),
  };
})();
