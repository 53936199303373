import { useMemo } from "react";
import { ProductFormProps, ProductFormValues } from "..";
import { Order, OrderProductInstance } from "api/orders/models";
import { Partials } from "api/other/models";
import { ProductEntity } from "api/products/models";
import { FLAVOR } from "CONSTANTS";
import { AdaptedOrder, AdaptedProduct } from "../ProductForm";
import cuid from "cuid";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useSelector } from "hooks";
import { productsActions } from "api/products/actions";
import { queryString } from "utilities";
import { getAttributesState } from "../utils/getAttributesState";
import { findPreselectedValues } from "../utils/findPreselectedValues";

export const useGetInitialFormValues = ({
  orderProduct,
  productEntity,
  formType,
  order,
  isFetchedAfterMount,
  indexes,
}: {
  orderProduct: ProductFormProps["productEntity"];
  formType: "edit" | "create";
  productEntity: ProductEntity | null;
  order: ProductFormProps["order"];
  isFetchedAfterMount: boolean;
  indexes: Record<string, number> | null;
}) => {
  const customers = useSelector(state => state.partials.customers);
  const {
    data: indexesPrices,
    isFetchedAfterMount: indexesPricesFetchedAfterMount,
  } = productsActions.useGetProductIndexesPrice(
    queryString.stringify({
      products: productEntity?.products.map(el => el.id) || [],
      priceList: order.customer?.priceList?.id || "",
    }),
    {
      enabled: Boolean(productEntity && order.customer?.priceList),
    },
  );

  const initialValues: ProductFormValues = useMemo(() => {
    if (!productEntity?.id) {
      return {
        productElements: [
          {
            product: 0,
            cuid: cuid(),
            attributesState: [],
            productSetCode: "",
            quantity: 1,
            index: null,
            shouldIncludeCustomerDiscountAmount: false,
            note: "",
            id: 0,
            name: "",
            amount: "0",
            currency: order.salesAccount.currency || "PLN",
          },
        ],
      };
    }

    if (formType === "edit") {
      assertIsDefined(orderProduct?.id);
      return getInitialStateForEdit(
        orderProduct.products,
        orderProduct.productSetCode,
        indexes || {},
        order.customer,
      );
    }

    return getInitialStateForCreate({
      products: productEntity?.products,
      productSetCode: productEntity?.productSetCode,
      orderSalesAccount: order.salesAccount,
      orderCustomer: order.customer,
      customers,
      indexes: indexes || {},
      indexesPrices: indexesPrices || {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderProduct?.id,
    productEntity?.id,
    order.customer,
    customers,
    formType,
    isFetchedAfterMount,
    indexesPricesFetchedAfterMount,
  ]);

  return initialValues;
};

function getInitialStateForEdit(
  products: OrderProductInstance[],
  productSetCode: string,
  indexes: Record<string, number>,
  orderCustomer: Order["customer"],
): ProductFormValues {
  const productElements = products.map(productElement => {
    const selectedIndex = productElement.index;
    const preselectedAttributes = findPreselectedValues(productElement);
    const name = (() => {
      if (FLAVOR === "b2b") return productElement.name;

      return productElement.product.name;
    })();

    return {
      attributesState: getAttributesState(
        productElement.product.attributes,
        indexes,
        preselectedAttributes,
        selectedIndex,
      ),
      cuid: cuid(),
      name,
      index: null,
      shouldIncludeCustomerDiscountAmount: productElement.includedCustomerDiscountAmount
        ? false
        : Number(orderCustomer?.discount || 0) > 0
        ? true
        : false,
      productSetCode: productSetCode,
      id: productElement.id,
      quantity: productElement.quantity,
      amount: productElement.amount as string,
      currency: productElement.currency,
      note: productElement.note,
      product: productElement.product.id,
    } as ProductFormValues["productElements"][number];
  });
  return { productElements };
}

function getInitialStateForCreate({
  products,
  productSetCode,
  customers,
  orderCustomer,
  orderSalesAccount,
  indexes,
  indexesPrices,
}: {
  products: ProductEntity["products"];
  productSetCode: ProductEntity["productSetCode"];
  customers: Partials["customers"];
  orderCustomer: Order["customer"];
  orderSalesAccount: AdaptedOrder["salesAccount"];
  indexes: Record<string, number>;
  indexesPrices: Record<number, string>;
}): ProductFormValues {
  const productElements = products.map(productElement => {
    const index = getProductIndex(productElement, indexes) || productElement.setMetaData?.index;
    if (index && orderCustomer) {
      const attributesState = getAttributesState(productElement.attributes, indexes, [], index);
      const amount = String(indexesPrices?.[index] || 0);
      const currency = getCustomerPriceList(orderCustomer.id, customers)?.currency || "PLN";

      return {
        quantity: productElement.setMetaData?.quantity || 1,
        cuid: cuid(),
        attributesState,
        name: productElement.name,
        productSetCode: productSetCode,
        id: productElement.id,
        amount,
        currency,
        product: productElement.id,
        shouldIncludeCustomerDiscountAmount: Number(orderCustomer?.discount || 0) > 0,
        index,
        note: "",
      } as ProductFormValues["productElements"][number];
    }

    if (productElement.setMetaData?.index) {
      const attributesState = getAttributesState(
        productElement.attributes,
        indexes,
        [],
        productElement.setMetaData.index,
      );

      return {
        quantity: productElement.setMetaData?.quantity || 1,
        cuid: cuid(),
        shouldIncludeCustomerDiscountAmount: Number(orderCustomer?.discount || 0) > 0,
        index: null,
        note: "",
        attributesState,
        name: productElement.name,
        productSetCode: productSetCode,
        id: productElement.id,
        amount: "0",
        currency: orderSalesAccount.currency || "PLN",
        product: productElement.id,
      } as ProductFormValues["productElements"][number];
    }

    return {
      quantity: productElement.setMetaData?.quantity || 1,
      shouldIncludeCustomerDiscountAmount: Number(orderCustomer?.discount || 0) > 0,
      index: null,
      note: "",
      name: productElement.name,
      productSetCode: productSetCode,
      attributesState: getAttributesState(productElement.attributes, indexes),
      id: productElement.id,
      cuid: cuid(),
      amount: "0",
      currency: orderSalesAccount.currency || "PLN",
      product: productElement.id,
    } as ProductFormValues["productElements"][number];
  });
  return { productElements };
}

function getCustomerPriceList(customerId: number, customers: Partials["customers"]) {
  const customer = customers.find(el => el.id === customerId);
  if (customer) {
    return customer.priceList;
  }
  return null;
}

export const getProductIndex = (
  product: AdaptedProduct | null,
  indexes: Record<string, number>,
  preselectedAttributes?: any,
) => {
  const attributes = getAttributesState(product?.attributes, indexes, preselectedAttributes);

  const attributesValues = Object.values(attributes).map(attr => attr.valueId);
  const allSelected = attributesValues.length > 0 && attributesValues.every(val => Boolean(val));
  if (allSelected) {
    const values = [...(attributesValues as number[])].sort((a, b) => (a > b ? 1 : -1));
    const index = values.join("-");
    if (product) {
      return indexes[index] || null;
    }
  }

  return null;
};
