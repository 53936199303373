import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";

export const etiquettes: ModuleLink = {
  url: "etiquettes",
  label: "Etykiety",
  icon: MdiChair,
  routing: () => <></>,
  isAccessible: false,
};
