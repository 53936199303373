import immer from "immer";
import { useEffect, useMemo, useState } from "react";
import { ErrorMessage, Spinner, CommonError } from "components/utils";
import { ArrayElement } from "typeUtilities";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import { OrderProductInstance, OrderProductInstanceAttribute, Order } from "api/orders/models";
import { Button } from "components/common";
import styles from "./ProductForm.module.css";
import cx from "classnames";
import { Assign } from "utility-types";
import { AttributeSection } from "./attributeSection/AttributeSection";
import { CURRENCY_TYPE, FLAVOR } from "CONSTANTS";
import { Product, ProductEntity } from "api/products/models";
import { SearchProduct } from "./SearchProduct";
import { assertIsDefined } from "utilities/assertIsDefined";
import cuid from "cuid";
import { SingleProductForm } from "./SingleProductForm";
import { generateCommonAttributesState } from "./utils/generateCommonAttributesState";
import { isMoreThanOneAttributeValue } from "./utils/isMoreThanOneAttributeValue";
import { queryString } from "utilities";
import { getProductIndexBasedOnAttributesState } from "./utils/getProductIndexBasedOnAttributesState";
import { productsActions } from "api/products/actions";
import { productSetActions } from "api/product-sets/actions";
import { useGetInitialFormValues } from "./hooks/useGetInitialFormValues";
import { Decimal } from "api/types";

export type AttributesState = {
  attributeId: number;
  valueId: number | null;
  picture: string | null;
}[];

export type AdaptedOrder = Pick<Order, "customer"> & {
  currency: Order["payment"]["currency"];
  salesAccount: { currency: Order["salesAccount"]["currency"] };
};

export type AdaptedProduct = Pick<Product, "attributes" | "picture" | "id" | "indexes">;

export type PreselectedValues = Assign<
  OrderProductInstanceAttribute,
  { value: ArrayElement<OrderProductInstanceAttribute["values"]> }
>[];

export interface ProductFormValues {
  productElements: {
    cuid: string;
    id: number;
    amount: Decimal;
    name: string;
    attributesState: AttributesState;
    quantity: number;
    productSetCode: string;
    currency: CURRENCY_TYPE;
    product: number;
    index: number | null;
    note: string;
    shouldIncludeCustomerDiscountAmount: boolean;
  }[];
}

export type SubmitEditProduct = (
  values: ProductFormValues,
  actions: FormikHelpers<ProductFormValues>,
  productEntity: ProductEntity | null,
  orderProductId: number | string,
) => void;

export type SubmitCreateProduct = (
  values: ProductFormValues,
  actions: FormikHelpers<ProductFormValues>,
  productEntity: ProductEntity | null,
) => void;

export interface ProductFormProps {
  closeForm?: () => void;
  isService?: boolean;
  productEntity?: Assign<ProductEntity, { products: OrderProductInstance[] }>;
  order: AdaptedOrder;
  submitEditProduct?: SubmitEditProduct;
  submitCreateProduct?: SubmitCreateProduct;
  overrides?: {
    showQuantityPicker?: boolean;
    showAmountPicker?: boolean;
    addButtonText?: string;
    removeHorizontalPadding?: boolean;
    CustomSearchProductComponent?: ({
      onChange,
    }: {
      onChange: (id: ProductEntity["id"]) => void;
    }) => React.ReactNode;
  };
}

export const ProductForm = ({
  closeForm,
  productEntity: orderProduct,
  order,
  submitEditProduct,
  submitCreateProduct,
  isService = false,
  overrides,
}: ProductFormProps) => {
  const addButtonText = overrides?.addButtonText ?? "Dodaj do zamówienia";
  const removeHorizontalPadding = overrides?.removeHorizontalPadding ?? false;
  const showAmountPicker = overrides?.showAmountPicker ?? true;
  const showQuantityPicker = overrides?.showQuantityPicker ?? true;

  // If there is product passed in props, it means that form has "edit" type
  const formType = orderProduct ? "edit" : "create";
  const [productToFetch, setProductToFetch] = useState<any>(
    orderProduct?.products[0].product ?? null,
  );

  /**
   * Clears state on product change
   */
  useEffect(() => {
    if (formType === "edit") {
      setProductToFetch(orderProduct?.products[0].product || null);
    }
  }, [formType, orderProduct]);

  const apiHook = useGetProductEntity({
    order,
  });
  const getProductId = useGetProductId();
  const { data: productEntity, error, isLoading: isFetchingProduct } = apiHook(
    getProductId({ order, productToFetch }) +
      queryString.stringify({ excludeIndexes: true, excludeIndexesData: true }),
    {
      enabled: Boolean(productToFetch),
    },
  );
  const { data: indexesPrices } = productsActions.useGetProductIndexesPrice(
    queryString.stringify({
      products: productEntity?.products.map(el => el.id) || [],
      priceList: order.customer?.priceList?.id || "",
    }),
    {
      enabled: Boolean(productEntity && order.customer?.priceList),
    },
  );

  const {
    data: indexes,
    error: indexesError,
    isLoading: isFetchingIndexes,
    isFetchedAfterMount,
  } = productsActions.useGetProductIndexes(
    queryString.stringify({
      products: productEntity?.products.map(el => el.id) || [],
      customer:
        FLAVOR === "b2b" && order.customer && !order?.customer.canAddNewIndexes
          ? order?.customer!.id
          : "",
    }),
    {
      enabled: Boolean(productEntity),
    },
  );

  const initialValues: ProductFormValues = useGetInitialFormValues({
    formType,
    indexes,
    isFetchedAfterMount,
    order,
    orderProduct,
    productEntity,
  });

  return (
    <div className={styles.form} key={productToFetch?.id}>
      <div
        className={cx(styles.section, {
          "px-0": removeHorizontalPadding,
        })}
      >
        {formType === "create" && !overrides?.CustomSearchProductComponent && (
          <SearchProduct
            isService={isService}
            order={order}
            setProductToFetch={setProductToFetch}
          />
        )}
        {overrides?.CustomSearchProductComponent &&
          overrides.CustomSearchProductComponent({ onChange: setProductToFetch })}

        {error || indexesError ? (
          <CommonError status={error?._httpStatus_ || indexesError?._httpStatus_} />
        ) : null}

        <div className="d-flex justify-content-center">
          {(isFetchingProduct || isFetchingIndexes) && <Spinner color="blue" />}
        </div>
      </div>
      {productEntity ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, helpers) => {
            if (formType === "create") {
              setProductToFetch(null);

              return submitCreateProduct?.(values, helpers, productEntity);
            }
            assertIsDefined(orderProduct);
            submitEditProduct?.(values, helpers, productEntity, orderProduct.id);
            closeForm?.();
          }}
          validate={values => validate(values, productEntity)}
          enableReinitialize
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            isValid,
            isSubmitting,
            setValues,
            submitForm,
          }) => {
            return (
              <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
                {Boolean(productEntity.commonAttributes.length) && (
                  <CommonAttributes
                    indexesPrices={indexesPrices}
                    order={order}
                    productEntity={productEntity}
                    overrides={overrides}
                  />
                )}
                {values.productElements.map((value, index) => (
                  <div className={cx({ "bg-white": index % 2 !== 0 })} key={value.cuid}>
                    <SingleProductForm
                      isTogglingEnable={formType === "create"}
                      product={productEntity.products[index]}
                      setFieldValue={(name, value) => {
                        setFieldValue(`productElements[${index}][${name}]`, value);
                      }}
                      values={values.productElements[index]}
                      formType={formType}
                      order={order}
                      productToFetch={productToFetch}
                      valueIndex={index}
                      showAmountPicker={showAmountPicker}
                      showQuantityPicker={showQuantityPicker}
                      changeAttribute={(attributeId, value, cuid, productIndexes) => {
                        const valuesWithSelectedAttribute: ProductFormValues = immer(
                          values,
                          draft => {
                            const productToUpdate = draft.productElements.find(
                              el => el.cuid === cuid,
                            );
                            assertIsDefined(productToUpdate);

                            const toChange = productToUpdate.attributesState.find(
                              el => el.attributeId === attributeId,
                            );

                            if (toChange) {
                              toChange.valueId = value;
                            }
                          },
                        );

                        const valuesWithUpdatedIndex = immer(valuesWithSelectedAttribute, draft => {
                          const productToUpdate = draft.productElements.find(
                            el => el.cuid === cuid,
                          );
                          assertIsDefined(productToUpdate);

                          const index = getProductIndexBasedOnAttributesState(
                            productIndexes,
                            productToUpdate.attributesState,
                          );

                          if (index) {
                            const amount = String(indexesPrices?.[index] || 0);
                            productToUpdate.amount = amount;
                            productToUpdate.index = index;
                          }
                        });

                        setValues(valuesWithUpdatedIndex);
                      }}
                    />
                  </div>
                ))}

                <div
                  className={cx("text-right", styles.section, {
                    "px-0": removeHorizontalPadding,
                  })}
                >
                  <ErrorMessage name="productElements" />
                </div>
                <div
                  className={cx("d-flex align-items-center justify-content-end", styles.section, {
                    "px-0": removeHorizontalPadding,
                  })}
                >
                  <Button
                    kind="secondary-stroke"
                    className="mr-2"
                    size="small"
                    onClick={() => closeForm?.()}
                  >
                    <span>Anuluj</span>
                  </Button>
                  <Button kind="primary" onClick={submitForm} size="small" disabled={isSubmitting}>
                    <span>
                      {
                        {
                          create: addButtonText || "Dodaj do zamówienia",
                          edit: "Gotowe",
                        }[formType]
                      }
                    </span>
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      ) : (
        <div
          className={cx("d-flex justify-content-end ", styles.section, {
            "px-0": removeHorizontalPadding,
          })}
        >
          {closeForm && (
            <Button
              kind="secondary-stroke"
              size="small"
              className="mr-1"
              onClick={() => closeForm?.()}
            >
              <span>Zamknij</span>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

const CommonAttributes = ({
  productEntity,
  order,
  overrides,
  indexesPrices,
}: Assign<
  Pick<ProductFormProps, "overrides" | "order">,
  { productEntity: ProductEntity; indexesPrices: Record<number, string> | null }
>) => {
  const { setValues, values } = useFormikContext<ProductFormValues>();
  const attributesToDisplay = useMemo(
    () => productEntity?.commonAttributes.map(attribute => ({ ...attribute, cuid: cuid() })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productEntity],
  );
  return (
    <div
      className={cx(styles.section, styles.commonAttributes, {
        "px-0": overrides?.removeHorizontalPadding,
      })}
    >
      <h6>Cechy wspólne</h6>
      <small className="text-color-grey">
        <i>Wybrane opcje zostaną zastosowane do wszystkich produktów z tą cechą</i>
      </small>
      <AttributeSection
        isCommonAttributes
        attributesState={generateCommonAttributesState(productEntity, values.productElements)}
        attributesToDisplay={attributesToDisplay || []}
        order={order}
        productsIds={productEntity.products.map(el => el.id)}
        changeAttribute={(attributeId, value, attributesState, productIndexes) => {
          setValues(
            immer(values, draft => {
              draft.productElements.forEach(productElement => {
                const product = productEntity.products.find(el => el.id === productElement.id);
                if (product && isMoreThanOneAttributeValue(product, attributeId)) {
                  const productToUpdate = productElement.attributesState.find(
                    el => el.attributeId === attributeId,
                  );

                  if (productToUpdate) {
                    if (!value) {
                      productToUpdate.valueId = value;
                    } else {
                      const possibleIndexValues = (() => {
                        const indexes = Object.keys(productIndexes || {});

                        const values: string[][] = [];
                        indexes.forEach(index => {
                          const indexArray = index.split("-");
                          values.push(indexArray);
                        });

                        return [...new Set(values.flat())].map(Number);
                      })();

                      if (possibleIndexValues?.includes(value)) {
                        productToUpdate.valueId = value;
                      }
                    }

                    const index = getProductIndexBasedOnAttributesState(
                      productIndexes,
                      productElement.attributesState,
                    );

                    if (index) {
                      const amount = String(indexesPrices?.[index] || 0);
                      productElement.amount = amount;
                      productElement.index = index;
                    }
                  }
                }
              });
            }),
          );
        }}
      />
    </div>
  );
};

const useGetProductEntity = ({ order }: { order: AdaptedOrder }) => {
  if (FLAVOR === "main") return productsActions.useGetProductEntity;

  if (order.customer?.canAddNewIndexes) {
    return productsActions.useGetProductEntity;
  }

  return productSetActions.useGetCustomerElementsB2b;
};

const useGetProductId = () => {
  return ({ order, productToFetch }: { order: AdaptedOrder; productToFetch: any }): string => {
    if (FLAVOR === "main") return productToFetch?.id;

    if (order.customer?.canAddNewIndexes) {
      return productToFetch?.id;
    }

    return `${order.customer?.id}-${productToFetch?.id}`;
  };
};

function validate(values: ProductFormValues, productEntity: ProductEntity | null) {
  const errors: Partial<{
    productElements: string;
  }> = {};
  if (values.productElements.some(el => Number(el.amount) < 0)) {
    errors.productElements = "Kwota nie może być ujemna.";
  }

  if (!values.productElements.every(el => el.index)) {
    errors.productElements = productEntity?.productSetCode
      ? "Wybierz wszystkie warianty produktów"
      : "Wybierz wariant produktu";
  }

  return errors;
}
