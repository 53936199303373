import { Route } from "api/routes/models";
import { RightPanelSection } from "components/utils/drawer";
import linkIcon from "assets/images/link.svg";
import { Button } from "components/common";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import { AsyncInput } from "components/utils";
import styles from "../RightPanel.module.css";
import { useRoutePatchMutation } from "api/logistics/routes/hooks";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiCopyAll } from "components/miloDesignSystem/atoms/icons/MdiCopyAll";

interface Props {
  route: Route;
}

export const TitleSection = ({ route }: Props) => {
  const context = useScrollableContext();
  const nameMutation = useRoutePatchMutation();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2" ref={context.triggerElementRef}>
        <div className="fs-24">{route.signature}</div>
        <CopyToClipboardComponent text={route.signature}>
          <Tooltip title="Skopiuj sygnaturę">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall btnBase16">
                <MdiCopyAll size="16" />
              </div>
            </Button>
          </Tooltip>
        </CopyToClipboardComponent>
        <CopyToClipboardComponent text={window.location.href}>
          <Tooltip title="Skopiuj link do strony">
            <Button kind="transparent-black" size="square-s">
              <div className="btnBase btnBaseSmall btnBase16">
                <img alt="kopiuj" src={linkIcon} />
              </div>
            </Button>
          </Tooltip>
        </CopyToClipboardComponent>
        <div className="position-relative ml-4 col-6 px-0">
          <AsyncInput
            disabled={nameMutation.isLoading}
            look="common"
            label="Nazwa trasy"
            onChange={value =>
              nameMutation.mutate({
                id: route.id,
                toUpdate: { name: value },
              })
            }
            overwrites={{
              input: { className: styles.formHeight },
              floatingLabel: { className: styles.inputTextLabel },
            }}
            value={route.name}
          />
        </div>
      </div>
      <div className="pt-2" />
    </RightPanelSection>
  );
};
