import { ProductEntity } from "api/products/models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { AttributesState, ProductFormValues } from "../ProductForm";
import { getAttributesState } from "./getAttributesState";
import { getIndexPossibleAttributeValues } from "utilities/getIndexPossibleAttributeValues";

export function generateCommonAttributesState(
  productEntity: ProductEntity,
  productElements: ProductFormValues["productElements"],
): AttributesState {
  const rawCommonAttributesState = productEntity.products
    .map(product => {
      const productValue = productElements.find(
        productElement => product.id === productElement.product,
      );
      if (productValue?.attributesState) {
        return productValue?.attributesState;
      }
      return getAttributesState(product.attributes, product.indexes);
    })
    .flat();

  const allProductEntityAttributes = productElements.flatMap(productElement => {
    const product = productEntity.products.find(el => el.id === productElement.id);
    assertIsDefined(product);
    const possibleIndexValues = getIndexPossibleAttributeValues({
      selectedIndexes: productElement.attributesState.map(el => el.valueId!).filter(Boolean),
      productIndexes: product.indexes || {},
    });

    return possibleIndexValues.length !== 0 ? productElement.attributesState : [];
  });

  const commonAttributesState = rawCommonAttributesState.map(attribute => {
    const attributes = allProductEntityAttributes.filter(
      el => el.attributeId === attribute.attributeId,
    );

    if (!attributes[0]) return attribute;
    const valueId = attributes[0].valueId;
    const areAllAttributesHaveTheSameValueId = attributes.every(el => el.valueId === valueId);
    if (areAllAttributesHaveTheSameValueId) {
      return { ...attribute, valueId };
    }
    return attribute;
  });

  return commonAttributesState;
}
