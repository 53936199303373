import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { IndexesRouting } from "routes/manufacturingNew/IndexesRouting";
import { MdiChairAlt } from "components/miloDesignSystem/atoms/icons/MdiChairAlt";

export const indexes: ModuleLink = {
  url: "indexes",
  label: "Warianty",
  icon: MdiChairAlt,
  routing: IndexesRouting,
};
