import { CURRENCY_TYPE } from "CONSTANTS";
import { ProductEntity } from "api/products/models";
import { AddLineItemToUnloadingPayload } from "api/wms/models";
import cuid from "cuid";
import { ProductFormValues } from "pages/orders/shared/rightPanel/productsSection/productForm";
import { getProductIndex } from "pages/orders/shared/rightPanel/productsSection/productForm/hooks/useGetInitialFormValues";
import { getAttributesState } from "pages/orders/shared/rightPanel/productsSection/productForm/utils/getAttributesState";

const initialProductElement: Omit<
  ProductFormValues["productElements"][number],
  "id" | "attributesState" | "name" | "productSetCode" | "product" | "_product" | "cuid"
> = {
  amount: "",
  quantity: 1,
  currency: "PLN",
  note: "",
  index: null,
  shouldIncludeCustomerDiscountAmount: false,
};

export function getInitialStateForCreate({
  products,
  productSetCode,
}: {
  products: ProductEntity["products"];
  productSetCode: ProductEntity["productSetCode"];
}): AddLineItemToUnloadingPayload["productElements"] {
  const productElements = products.map(productElement => {
    const index =
      getProductIndex(productElement, productElement.indexes) || productElement.setMetaData?.index;
    const currency = "PLN" as CURRENCY_TYPE;
    if (index) {
      const attributesState = getAttributesState(
        productElement.attributes,
        productElement.indexes,
        [],
        index,
      );
      const amount = String(productElement.indexData[index].amount);

      return {
        ...initialProductElement,
        index: index,
        quantity: productElement.setMetaData?.quantity || 1,
        cuid: cuid(),
        attributesState,
        name: productElement.name,
        productSetCode: productSetCode,
        id: productElement.id,
        amount,
        currency,
        product: productElement.id,
      };
    }

    if (productElement.setMetaData?.index) {
      const attributesState = getAttributesState(
        productElement.attributes,
        productElement.indexes,
        [],
        productElement.setMetaData.index,
      );

      return {
        ...initialProductElement,
        quantity: productElement.setMetaData?.quantity || 1,
        cuid: cuid(),
        attributesState,
        name: productElement.name,
        productSetCode: productSetCode,
        id: productElement.id,
        amount: "0",
        currency,
        product: productElement.id,
      };
    }

    return {
      ...initialProductElement,
      quantity: productElement.setMetaData?.quantity || 1,
      name: productElement.name,
      productSetCode: productSetCode,
      attributesState: getAttributesState(productElement.attributes, productElement.indexes),
      id: productElement.id,
      cuid: cuid(),
      amount: "0",
      currency,
      product: productElement.id,
    };
  });

  return productElements;
}
