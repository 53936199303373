import { Button } from "components/common";
import styles from "../RouteOrdersList.module.css";
import onCallIcon from "assets/images/phoneInTalk_black.svg";
import { useCallCenterRouteOrder, useRoutePatchMutation } from "api/call-center/routes/hooks";
import { dateFns } from "utilities";
import phoneMissed from "assets/images/phoneMissed.svg";
import { CallStatus } from "api/call-center/routes/models";
import { MdiCallEnd } from "components/miloDesignSystem/atoms/icons/MdiCallEnd";

interface Props {
  callInProgressId: number;
  setCallInProgress: React.Dispatch<React.SetStateAction<number | null>>;
}

export const CallInProgressPopUp = function({ callInProgressId, setCallInProgress }: Props) {
  const usePatchMutation = useRoutePatchMutation();
  const { data: routeOrder } = useCallCenterRouteOrder(callInProgressId);

  const handleEndCall = (value: CallStatus) => {
    usePatchMutation.mutate({ id: callInProgressId, toUpdate: { callStatus: value } });
    setCallInProgress(null);
  };

  if (!routeOrder) return null;

  const hourMin = routeOrder.delivery.minimumCollectionHour;
  const hourMax = routeOrder.delivery.maximumCollectionHour;

  let plannedDeliveryTime = routeOrder.delivery.plannedDeliveryTime
    ? dateFns.format(new Date(), "EEEE dd.MM.yyyy")
    : null;
  if (plannedDeliveryTime) {
    plannedDeliveryTime =
      plannedDeliveryTime.charAt(0).toUpperCase() + plannedDeliveryTime.slice(1);
  }

  return (
    <div className={styles.callCard}>
      <div className="px-4 pt-4 pb-2">
        <div>
          <div className="d-flex justify-content-between gap-4 relative">
            <div>
              <div className={styles.labelText}>Dzwonisz do:</div>
              <div className={styles.client}>
                {routeOrder.delivery.fistName} {routeOrder.delivery.lastName}
              </div>
              <strong className="fs-14 fw-400 pt-1"> {routeOrder.delivery.phone}</strong>
              <div className="d-flex align-items-center gap-2 py-2">
                <span className="body-14-600 pt-1 nowrap">{routeOrder.signature}</span>
                <div className={styles.separator}></div>
                <div className={styles.labelText}>Dostawa</div>
                <div className="body-14-600 nowrap">
                  {plannedDeliveryTime}&nbsp;
                  {hourMin}
                  {hourMin && hourMax && "-"}
                  {hourMax}
                </div>
              </div>
            </div>
            <div style={{ position: "absolute", right: "20px" }} className="pt-2 px-5 ">
              <div className={styles.phone}>
                <img src={onCallIcon} alt="" />
              </div>
              <div className={styles.circle1}></div>
              <div className={styles.circle2}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2 px-4 pt-2 pb-4 ">
        <Button
          size="btnSizeMedium"
          onClick={() => handleEndCall("CALLED")}
          kind="btnBgRed"
          className="btn uppercase"
        >
          <div className="btnBase btnBase16 btnBaseMedium">
            <MdiCallEnd size="16" />
            Zakończ rozmowę
          </div>
        </Button>
        <Button
          size="btnSizeMedium"
          onClick={() => handleEndCall("NO_CALL_YET")}
          kind="btnBgWhite5"
          className="btn btnSizeMedium btnBgWhite5 uppercase"
        >
          <div className="btnBase btnBase16 btnBaseMedium">
            <img src={phoneMissed} alt="" />
            Klient nie odebrał
          </div>
        </Button>
      </div>
    </div>
  );
};
