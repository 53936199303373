import {
  ProductionPlan,
  SelectedSource,
  ShelfFilters,
  ShelfItem,
} from "api/new-production-plans/models";
import styles from "../../../ProductionPlanDetails.module.css";
import localStyles from "./ShelfContent.module.css";
import cx from "classnames";
import { Button } from "components/common";
import { ErrorType } from "hooks/createApiQuery";
import { CommonError, Spinner } from "components/utils";
import { ShelfItemDetails } from "./subcomponents/shelfItemDetails/ShelfItemDetails";
import { useState } from "react";
import { useEffect } from "react";
import { useToggle } from "hooks";
import { AutoAssignModal } from "./subcomponents/autoAssignModal/AutoAssignModal";
import React from "react";
import { MdiEvent } from "components/miloDesignSystem/atoms/icons/MdiEvent";

interface Props {
  error: ErrorType | null;
  isLoading: boolean;
  productionPlan: ProductionPlan;
  search: string;
  selectedSources: SelectedSource[];
  shelfHeight: number;
  shelfItems: ShelfItem[];
  shelfFilters: ShelfFilters;
  setKeyStrings: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

export const ShelfContent = ({
  error,
  isLoading,
  productionPlan,
  search,
  selectedSources,
  shelfHeight,
  shelfItems,
  shelfFilters,
  setKeyStrings,
}: Props) => {
  const [shelfItemHeight, setShelfItemHeight] = useState(shelfHeight - 100);
  const autoAssignModal = useToggle();

  useEffect(() => {
    setShelfItemHeight(prev => {
      if (shelfHeight > prev) {
        return shelfHeight - 100;
      }
      return prev;
    });
  }, [shelfHeight]);

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (isLoading) {
    return (
      <div className={localStyles.shelfContent} style={{ height: `${shelfHeight}px` }}>
        <div className="d-flex align-items-center justify-content-center mt-5">
          <Spinner color="blue" size="big" on={isLoading} text="trwa wczytywanie" />
        </div>
      </div>
    );
  }

  return (
    <div
      className={localStyles.shelfContent}
      style={{ height: `${shelfHeight - 100}px`, maxHeight: `${shelfHeight - 100}px` }}
    >
      <div
        className={cx(styles.scrollXWrapper, "px-3 pb-0", {
          [localStyles.contentHeight]:
            Object.keys(productionPlan.shelfFilters).length !== 0 || shelfItems,
        })}
      >
        <div className="d-flex flex-nowrap">
          {shelfItems.map((shelfItem, index) => (
            <React.Fragment key={index}>
              <div
                className={cx(localStyles.shelfItem, "pr-2 py-2 mr-2")}
                style={{ height: `${shelfItemHeight}px` }}
              >
                <div className={cx(localStyles.shelfItemHeader, "justify-content-between")}>
                  <div>
                    <MdiEvent size="20" />
                    <div className="body-14-600">{shelfItem.header}</div>
                  </div>
                  <Button kind="create" onClick={autoAssignModal.open} style={{ height: "26px" }}>
                    <div className="btnBase btnBaseSmall">Rozłóż automatycznie</div>
                  </Button>
                </div>
                <ShelfItemDetails
                  filterValue={shelfItem.filterValue}
                  header={shelfItem.header}
                  origin={shelfItem.origin}
                  productionPlan={productionPlan}
                  search={search}
                  selectedSources={selectedSources}
                  setKeyStrings={setKeyStrings}
                  setShelfItemHeight={setShelfItemHeight}
                  shelfHeight={shelfHeight}
                />
              </div>
              {autoAssignModal.isOpen && (
                <AutoAssignModal
                  close={autoAssignModal.close}
                  filters={shelfFilters}
                  productionPlanId={productionPlan.id}
                  search={search}
                  shelfItem={shelfItem}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
