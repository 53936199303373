import { AxiosError } from "axios";
import { SalesAccount } from "./sales-accounts/models";
import { User } from "./users/models";

export interface Pagination<Res> {
  results: Res[];
  count: number;
  next: string | null;
  previous: string | null;
  limit: number;
  pageSize: number;
}

export interface PaginationWithCounters<Res, CounterName extends string> extends Pagination<Res> {
  counters: { salesAccountId: SalesAccount["id"] } & { [P in CounterName]: number }[];
}

// TODO - fix queryFetch error type
// export type QueryFetchError = { data: Record<string, string> } & Pick<AxiosError, "response">;
export type QueryFetchError = AxiosError;

/**
 * @example d35e760b-67ea-43f0-b0e2-80eba9681b7b
 */
export type UUID = string;

/**
 * @example 2024-04-11
 */
export type ISODate = string;

/**
 * @example 20 10:40:30
 */
export type ISODuration = string;
/**
 * @example 15:23:40
 */
export type ISOTime = string;

/**
 * @example 2024-03-26T05:21:00+01:00
 */
export type ISODateTime = string;

/**
 * @example "0.0"
 */
export type Decimal = string;

/**
 * Backend returns amounts in sub units (ex. "grosze") as integer
 * @example 31489111
 */
export type SubUnits = number;

/**
 * @example created: "2024-12-12"
 */
export type QueryParams = {
  [key: string]: string;
};

export type JSONField = Record<string, any>;

/**
 * @example navigate: "/orders/list/active/all"
 * @example preview: "orders/list/active/all"
 */
export type Path = string;

export type UserPartial =
  | (Pick<User, "firstName" | "id" | "lastName"> & { avatar: string | null; [key: string]: any })
  | null;

export type UserShortType = Pick<User, "firstName" | "lastName" | "avatar"> | null;

export type PAYMENT_TYPE = "CASH_ON_DELIVERY" | "ONLINE" | "DEBIT_CARD" | "INSTALMENT";

export enum BasePaymentType {
  ONLINE = "ONLINE",
  ON_DELIVERY = "ON_DELIVERY",
  CASH = "CASH",
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  DEBIT_CARD = "DEBIT_CARD",
  SPLIT_PAYMENT = "SPLIT_PAYMENT",
  WIRE_TRANSFER = "WIRE_TRANSFER",
  INSTALMENT = "INSTALMENT",
  PAYMENT_INTO_ACCOUNT = "PAYMENT_INTO_ACCOUNT",
}

export type TabProps = { label: string; name: string }[];

export type GetSearchProps<T> = {
  tab: T;
  query: QueryParams;
};
