import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiChairAlt } from "components/miloDesignSystem/atoms/icons/MdiChairAlt";

export const indexes: ModuleLink = {
  url: "indexes",
  label: "Indeksy",
  icon: MdiChairAlt,
  routing: () => <></>,
  isAccessible: false,
};
