import { Button } from "components/common";
import whiteRouteIcon from "assets/images/routeWhite.svg";
import whiteGroupIcon from "assets/images/groupsWhite.svg";
import dayColumnIcon from "assets/images/day_column.svg";
import framePersonIcon from "assets/images/frame_person.svg";
import localStyles from "./LeftToolbar.module.css";
import { useToggle } from "hooks";
import { AddEmployeeModal } from "../addEmployeeModal/AddEmployeeModal";
import { ShelfFilters } from "api/new-production-plans/models";
import { AddWorkingDayModal } from "../addWorkingDayModal/AddWorkingDayModal";
import { AddRoutesFiltersModal } from "../addRoutesFiltersModal/AddRoutesFiltersModal";
import { useState } from "react";
import { ProductionPlan } from "api/new-production-plans/models";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";

interface Props {
  productionPlan: ProductionPlan;
  refetch: () => void;
  shelfFilters: ShelfFilters;
  setShelfFilters: React.Dispatch<React.SetStateAction<ShelfFilters>>;
}

export interface CheckedElement {
  checked: boolean;
  id: number;
}

export const LeftToolbar = ({ productionPlan, refetch, shelfFilters, setShelfFilters }: Props) => {
  const [checked, setChecked] = useState<CheckedElement[]>([]);

  const addEmployeeModal = useToggle();
  const addWorkingDayModal = useToggle();
  const addRoutesFiltersModal = useToggle();

  const closeRoutesFiltesModal = (): void => {
    setChecked([]);
    addRoutesFiltersModal.close();
  };

  return (
    <div className={localStyles.leftToolbar}>
      <Button kind="square" onClick={addEmployeeModal.open}>
        <img alt="pracownicy" src={framePersonIcon} />
      </Button>

      <Button kind="square" onClick={addWorkingDayModal.open}>
        <img alt="dni" src={dayColumnIcon} />
      </Button>

      <hr className="hr-white w-100" />

      <Button disabled kind="square">
        <MdiChair color="neutralWhite100" size="18" />
      </Button>

      <Button kind="square" onClick={addRoutesFiltersModal.open}>
        <img alt="trasy" src={whiteRouteIcon} />
      </Button>

      <Button disabled kind="square">
        <img alt="grupy" src={whiteGroupIcon} />
      </Button>

      {addEmployeeModal.isOpen && (
        <AddEmployeeModal
          assignedEmployees={productionPlan.employees}
          close={addEmployeeModal.close}
          productionPlanId={productionPlan.id}
          refetch={refetch}
        />
      )}
      {addWorkingDayModal.isOpen && (
        <AddWorkingDayModal
          close={addWorkingDayModal.close}
          productionPlanId={productionPlan.id}
          refetch={refetch}
          workingDays={productionPlan.days}
        />
      )}
      {addRoutesFiltersModal.isOpen && (
        <AddRoutesFiltersModal
          close={closeRoutesFiltesModal}
          checkedRoutes={checked}
          setCheckedRoutes={setChecked}
          shelfFilters={shelfFilters}
          setShelfFilters={setShelfFilters}
        />
      )}
    </div>
  );
};
