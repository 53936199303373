import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiAccountBalance } from "components/miloDesignSystem/atoms/icons/MdiAccountBalance";
import { BankAccounts } from "routes/BankAccounts";

export const bankAccounts: ModuleLink = {
  url: "bank-accounts",
  label: "Konta bankowe",
  icon: MdiAccountBalance,
  routing: BankAccounts,
};
