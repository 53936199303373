import errorIcon from "assets/images/877.svg";
import styles from "./ConversationModal.module.css";
import cx from "classnames";
import { RouteMessages } from "api/routes/models";
import { clickOutsideIgnoreClass } from "./RightPanel";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";

export const OrderConversation = ({
  order,
  openRightPanel,
}: {
  order: RouteMessages["orders"][number];
  openRightPanel: (id: number) => void;
}) => {
  const lastMessage = order.messages.lastIncomingMessage;
  const confirmMessage = order.messages.confirmationMessage;

  if (
    confirmMessage?.failureReason &&
    (confirmMessage.status === "UNDELIVERED" || confirmMessage.status === "UNSENT")
  ) {
    return (
      <div className="d-flex flex-column">
        <div className={styles.error}>
          <img src={errorIcon} alt="" />
          {confirmMessage?.failureReason || "Wystąpił błąd w wysyłaniu wiadomości"}
        </div>
        <div
          className={cx(
            clickOutsideIgnoreClass,
            "d-flex align-items-center gap-1 mt-2",
            styles.conversationBtn,
          )}
          onClick={() => openRightPanel(order.id)}
        >
          Cała konwersacja <MdiArrowForward color="deepPurple500" size="12" />
        </div>
      </div>
    );
  }

  if (lastMessage) {
    return (
      <div className={styles.message}>
        <span>{lastMessage.body}</span>
        <div
          className={cx(
            clickOutsideIgnoreClass,
            "d-flex align-items-center gap-1 mt-2",
            styles.conversationBtn,
          )}
          onClick={() => openRightPanel(order.id)}
        >
          Cała konwersacja <MdiArrowForward color="deepPurple500" size="12" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.noMessage}>Brak odpowiedzi </div>
      <div
        className={cx(
          clickOutsideIgnoreClass,
          "d-flex align-items-center gap-1 mt-2",
          styles.conversationBtn,
        )}
        onClick={() => openRightPanel(order.id)}
      >
        Cała konwersacja <MdiArrowForward color="deepPurple500" size="12" />
      </div>
    </div>
  );
};
