import { Order } from "api/orders/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import styles from "../RightPanel.module.css";
import { shippingServiceConstants } from "constants/shippingServiceConstants";
import { dateFns } from "utilities";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MarketplaceShipping } from "components/common/marketplaceShipping/MarketplaceShipping";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";
import { openShipmentCreationSummary } from "components/common/shipmentsSection/openShipmentCreationSummary";
import { CreateInternalShipmentHandler } from "components/common/createInternalShipmentHandler/CreateInternalShipmentHandler";

export const ShippingTable = ({ order }: { order: Order }) => {
  const columns = useShipmentsColumns();

  return (
    <div className="my-2">
      <div className="d-flex align-items-center justify-content-between">
        <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-2">
          Przesyłki
        </Typography>
        <CreateInternalShipmentHandler>
          {({ createInternalShipmentsMutation, openResponseModal }) => (
            <div className="w-100 d-flex justify-content-end">
              <Button
                className="mb-2 text-uppercase"
                isLoading={createInternalShipmentsMutation.isLoading}
                onClick={() =>
                  createInternalShipmentsMutation.mutate(
                    {
                      orders: [order.id],
                    },
                    {
                      onSuccess: payload => openShipmentCreationSummary(payload, openResponseModal),
                    },
                  )
                }
                size="small"
                startIcon={MdiAdd}
                variant="gray"
              >
                Utwórz przesyłkę
              </Button>
            </div>
          )}
        </CreateInternalShipmentHandler>
      </div>
      <Table<Order["shipments"][number]>
        rows={order.shipments}
        columns={columns}
        overrides={() => ({
          hideHeader: !order.shipments.length,
          noResultComponent: <EmptySection label="Brak przesyłek" />,
        })}
        isLoading={false}
        error={null}
        uiSchema={comfortableListUiSchema}
      />
      {Boolean(order.shipments.length) && <MarketplaceShipping orderId={order.id} />}
    </div>
  );
};

export const useShipmentsColumns = () => {
  return useCreateTableColumns<Order["shipments"][number]>(({ columnHelper }) => {
    return [
      columnHelper.link({
        header: "sygnatura",
        textRenderer: row => row.signature,
        to: row => `/logistics/shipping-shipments/list/all?panelId=${row.id}`,
        size: 140,
        typographyProps: { fontSize: "14" },
      }),
      columnHelper.text(row => row.shippingService?.name, {
        header: "konto",
        size: 140,
        typographyProps: { fontSize: "14" },
      }),
      columnHelper.accessor(row => row.shippingService, {
        id: "shippingIcon",
        header: "",
        size: 70,
        cell: info => {
          const shippingService = info.getValue() as Order["shipments"][number]["shippingService"];
          if (!shippingService) return "";
          return (
            <div className={styles.courierLogoWrapper}>
              <img
                className={styles.courierLogo}
                alt="Kurier"
                src={shippingServiceConstants[shippingService.provider].logo}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.courierDeliveredDate, {
        header: "status",
        size: 140,
        cell: info => {
          const isDelivered = info.getValue();
          if (isDelivered) {
            return <Tag label="dostarczono" variant="success" />;
          }
          return <Tag label="nie dostarczono" variant="warning" />;
        },
      }),
      columnHelper.text(
        row => {
          if (row.courierDeliveredDate) {
            return dateFns.format(new Date(row.courierDeliveredDate), "dd.MM.yyyy, HH:mm");
          }
        },
        {
          header: "data dostarczenia",
          size: 108,
          typographyProps: {
            fontWeight: "600",
          },
        },
      ),
    ];
  });
};
