import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Paper } from "components/miloDesignSystem/atoms/paper";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Select } from "components/miloDesignSystem/molecules/select";
import styles from "./CreateCustomer.module.css";
import { Formik, FormikHelpers } from "formik";
import { useSalesSettings, useSelector, useToastr, useToggle } from "hooks";
import { cx } from "utilities";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { CURRENCY, availablePaymentTypesDict, countryCodes, paymentTypeDict } from "CONSTANTS";
import { useNavigate } from "hooks/useNavigate";
import { customerActions } from "api/customers/actions";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Line } from "components/miloDesignSystem/atoms/line";
import { GusDataModal } from "components/miloDesignSystem/organisms/gusDataModal/GusDataModal";
import { ColorPicker } from "components/miloDesignSystem/atoms/colorPicker";
import { validationSchema } from "components/common/createCustomerFormWrapper/validationSchema";
import { CustomerFormValues } from "components/common/createCustomerFormWrapper/types";
import { BasePaymentType } from "api/types";
import { Sales } from "api/milo-settings/models";
import { OrderPaymentType } from "api/orders/models";

const initialValues: CustomerFormValues = {
  businessRegisterNumber: "",
  city: "",
  color: colorPalette.deepPurple400,
  countryCode: "PL",
  defaultCurrency: "PLN",
  defaultPaymentType: BasePaymentType.CASH_ON_DELIVERY,
  discount: "0",
  email: "",
  fullName: "",
  hasPermissionsToCreateProducts: false,
  hasPermissionsToEditWarehouseDeliveryDate: false,
  hasPermissionsToSelectSalesAccountOnCreateOrder: false,
  isRecipient: false,
  name: "",
  ownerFirstName: "",
  ownerLastName: "",
  phone: "",
  postCode: "",
  salesAccount: null,
  street: "",
  taxId: "",
  textColor: colorPalette.neutralWhite100,
  website: EMPTY_VALUE,
};

export const CreateCustomer = () => {
  const salesAccounts = useSelector(store => store.partials.salesAccounts);
  const salesAccountsOptions = salesAccounts.map(salesAccount => ({
    text: salesAccount.name,
    type: MenuItemType.TEXT,
    value: salesAccount.id,
  }));
  const countriesOptions = countryCodes.map(country => ({
    icon: country.icon,
    text: `${country.value} (${country.name})`,
    type: MenuItemType.ICON,
    value: country.value,
  }));
  const toastr = useToastr();
  const gusDataModal = useToggle();
  const navigate = useNavigate();
  const postMutation = customerActions.usePostCustomer();
  const { paymentType } = useSalesSettings();

  const paymentTypeOptions = Object.entries(paymentType)
    .filter(([, value]) => value)
    .map(([id]) => {
      const type = paymentTypeDict[id as keyof Sales["paymentType"]] as OrderPaymentType;
      return {
        value: type,
        text: availablePaymentTypesDict[type],
        type: MenuItemType.TEXT,
      };
    });

  const handleSubmit = (
    values: CustomerFormValues,
    actions: FormikHelpers<CustomerFormValues>,
  ): void => {
    postMutation.mutate(
      values,
      handleSubmitResponse(actions, {
        onSuccess: () => {
          navigate("/crm/customers");
        },
      }),
    );
  };

  return (
    <PageWrapper>
      <PageHeader title="Utwórz kontrahenta" searchBar={false} viewLabel="CREATE_CUSTOMER" />
      <div className="d-flex p-3 d-flex flex-column overflow-hidden flex-1">
        <Paper className="w-100 d-flex flex-1 overflow-hidden">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, isSubmitting, isValid, setValues, values }) => (
              <form
                className={cx("flex-1 overflow-auto", {
                  "was-validated": !isValid,
                })}
                onSubmit={handleSubmit}
              >
                <div className={cx(styles.content, "d-flex flex-column px-3 pt-2 pb-4")}>
                  <div className="pt-3 pb-2">
                    <Typography
                      className="pb-2 text-uppercase"
                      color="neutralBlack48"
                      fontSize="10"
                      fontWeight="700"
                    >
                      Powiązany marketplace
                    </Typography>
                    <div className="pt-2 pb-1">
                      <Select.Form<CustomerFormValues>
                        items={salesAccountsOptions}
                        label="Marketplace"
                        name="salesAccount"
                        textFieldProps={{
                          containerClassName: styles.formContainer,
                        }}
                      />
                    </div>
                  </div>
                  <div className={cx("pt-2 pb-1", styles.formContainer)}>
                    <Select.Form<CustomerFormValues>
                      items={countriesOptions}
                      label="Kod kraju"
                      name="countryCode"
                      textFieldProps={{
                        containerClassName: "w-50",
                      }}
                    />
                  </div>
                  <div className="pt-3 pb-2">
                    <Typography
                      className="pb-2 text-uppercase"
                      color="neutralBlack48"
                      fontSize="10"
                      fontWeight="700"
                    >
                      Numery identyfikacyjne firmy
                    </Typography>
                    <div className="pt-2 pb-1 d-flex gap-2 align-items-baseline">
                      <div className={styles.formContainer}>
                        <TextField.Form<CustomerFormValues>
                          helperText="Musi składać się z 10 cyfr"
                          label="NIP"
                          name="taxId"
                          placeholder="000 000 00 00"
                          size="small"
                        />
                      </div>
                      <Button
                        className="text-uppercase"
                        onClick={() => {
                          if (!values.taxId) {
                            toastr.open({
                              title: "Brak NIP",
                              text: "W celu pobrania danych z GUS - uzupełnij NIP",
                              type: "warning",
                            });
                            return;
                          }
                          gusDataModal.open();
                        }}
                        size="small"
                        variant="gray"
                      >
                        Pobierz dane z GUS
                      </Button>
                    </div>
                    <div className={cx("pt-2 pb-1", styles.formContainer)}>
                      <TextField.Form<CustomerFormValues>
                        helperText="Musi składać się z 9 lub 14 cyfr"
                        label="REGON"
                        name="businessRegisterNumber"
                        placeholder="000 000 000"
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="pt-3 pb-2">
                    <Typography
                      className="pb-2 text-uppercase"
                      color="neutralBlack48"
                      fontSize="10"
                      fontWeight="700"
                    >
                      Nazwa firmy i przedstawiciela
                    </Typography>
                    <div className={cx("pt-2 pb-1", styles.formContainer)}>
                      <TextField.Form<CustomerFormValues>
                        helperText="Będzie używana do dokumentów finansowych i oficjalnej korespondencji"
                        label="Pełna nazwa"
                        name="fullName"
                        placeholder="np. Milo sp. z o.o."
                        size="small"
                      />
                    </div>
                    <div className={cx("pt-2 pb-1", styles.formContainer)}>
                      <TextField.Form<CustomerFormValues>
                        helperText="Będzie wyświetlana w aplikacji"
                        label="Nazwa skrócona"
                        name="name"
                        placeholder="np. Milo"
                        size="small"
                      />
                    </div>
                    <div className={cx("pt-2 pb-1", styles.formContainer)}>
                      <TextField.Form<CustomerFormValues>
                        label="Imię właściciela lub przedstawiciela [opcjonalnie]"
                        name="ownerFirstName"
                        size="small"
                      />
                    </div>
                    <div className={cx("pt-2 pb-1", styles.formContainer)}>
                      <TextField.Form<CustomerFormValues>
                        label="Nazwisko właściciela lub przedstawiciela [opcjonalnie]"
                        name="ownerLastName"
                        size="small"
                      />
                    </div>
                  </div>
                  <div className="pt-3 pb-2">
                    <div className="pb-2 d-flex flex-column gap-1">
                      <Typography
                        className="pb-2 text-uppercase"
                        color="neutralBlack48"
                        fontSize="10"
                        fontWeight="700"
                      >
                        Dane teleadresowe
                      </Typography>
                      <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                        Poniższe dane będą umieszczane na dokumentach
                      </Typography>
                    </div>
                    <div className={cx("pt-2 pb-1", styles.formContainer)}>
                      <TextField.Form<CustomerFormValues>
                        helperText="Np. nazwa miejscowości, ulica, nr budynku, nr lokalu"
                        label="Adres siedziby"
                        name="street"
                        placeholder="np. ul. Wielka 25"
                        size="small"
                      />
                    </div>
                    <div className={cx("pt-2 pb-1 d-flex gap-2", styles.formContainer)}>
                      <div className="w-30">
                        <TextField.Form<CustomerFormValues>
                          label="Kod pocztowy"
                          name="postCode"
                          placeholder="00-000"
                          size="small"
                        />
                      </div>
                      <div className="w-100">
                        <TextField.Form<CustomerFormValues>
                          label="Miasto"
                          name="city"
                          placeholder="np. Poznań"
                          size="small"
                        />
                      </div>
                    </div>
                    <div className={cx("pt-2 pb-1", styles.formContainer)}>
                      <TextField.Form<CustomerFormValues>
                        label="Telefon"
                        name="phone"
                        placeholder="000000000"
                        size="small"
                      />
                    </div>
                    <div className={cx("pt-2 pb-1", styles.formContainer)}>
                      <TextField.Form<CustomerFormValues>
                        label="Email"
                        name="email"
                        placeholder="przyklad@przyklad.pl"
                        size="small"
                      />
                    </div>
                  </div>
                  <Line className="my-2" lineStyle="dashed" />
                  <div className="pt-3 pb-2">
                    <div className="pt-2 pb-1">
                      <Select.Form<CustomerFormValues>
                        items={CURRENCY.map(currency => ({
                          type: MenuItemType.TEXT,
                          value: currency.id,
                          text: currency.name,
                        }))}
                        label="Domyślna waluta kontrahenta"
                        name="defaultCurrency"
                        textFieldProps={{
                          containerClassName: styles.formContainer,
                        }}
                      />
                    </div>
                    <div className="pt-2 pb-1">
                      <Select.Form<CustomerFormValues>
                        items={paymentTypeOptions}
                        label="Domyślna metoda płatności"
                        name="defaultPaymentType"
                        textFieldProps={{
                          containerClassName: styles.formContainer,
                        }}
                      />
                    </div>
                    <div className={cx("pt-2 pb-1", styles.formContainer)}>
                      <div className="w-30">
                        <TextField.Form<CustomerFormValues>
                          label="Rabat [%]"
                          name="discount"
                          placeholder="0"
                          size="small"
                          type="number"
                        />
                      </div>
                    </div>
                    <div className="pt-2 pb-1 d-flex flex-column gap-2">
                      <div>
                        <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
                          Kolor etykietki
                        </Typography>
                        <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                          Etykietka z nazwą kontrahenta będzie wyświetlana w aplikacji np. na liście
                          zamówień
                        </Typography>
                      </div>
                      <ColorPicker.Form<CustomerFormValues>
                        backgroundColorName="color"
                        textColorName="textColor"
                        width={545}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 py-3">
                    <Button
                      className="text-uppercase"
                      onClick={() => navigate(`/crm/customers`)}
                      size="medium"
                      variant="transparent"
                    >
                      Anuluj
                    </Button>
                    <Button
                      className="text-uppercase"
                      isLoading={isSubmitting}
                      size="medium"
                      type="submit"
                      variant="deepPurple"
                    >
                      Gotowe, utwórz kontrahenta
                    </Button>
                  </div>
                </div>
                {gusDataModal.isOpen && (
                  <GusDataModal
                    close={gusDataModal.close}
                    onConfirmClick={data =>
                      setValues({
                        ...values,
                        ...data,
                      })
                    }
                    taxId={values.taxId}
                  />
                )}
              </form>
            )}
          </Formik>
        </Paper>
      </div>
    </PageWrapper>
  );
};
