import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { Reservations } from "routes/wms/Reservations";
import { MdiBook } from "components/miloDesignSystem/atoms/icons/MdiBook";

export const reservations: ModuleLink = {
  url: "reservations",
  label: "Rezerwacje",
  icon: MdiBook,
  routing: Reservations,
  isAccessible: false,
};
