import { CreateManufacturingOrderItem } from "api/external-manufacturing/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useExternalManufacturingOrdersColumns } from "./useExternalManufacturingOrdersColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import styles from "../OrderList.module.css";
import localStyles from "./CreateExternalManufacturingOrderModal.module.css";
import { cx, pluralize } from "utilities";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import { useFilters } from "hooks/useFilters";
import { ProductKind } from "api/products/models";
import { FiltersSection } from "./components/FiltersSection";
import { validationSchema } from "./validationSchema";

interface Props {
  close: () => void;
  state: {
    manufacturingItems: CreateManufacturingOrderItem[];
  };
}

export interface Filters {
  productKind: ProductKind | "";
}

export type FormValues = (CreateManufacturingOrderItem & { isSelected: boolean })[];

export const CreateExternalManufacturingOrderModal = ({ close, state }: Props) => {
  const postCreateManufacturingOrderFromItems = externalManufacturingActions.useCreateManufacturingOrderFromItems(
    close,
  );
  const { filters, setFilter } = useFilters<Filters>({
    productKind: "",
  });

  const initialValues: FormValues = state.manufacturingItems.map(manufacturingItem => ({
    ...manufacturingItem,
    isSelected: false,
  }));

  const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    const manufacturingOrderItemsDTO: CreateManufacturingOrderItem[] = values
      .filter(
        manufacturingOrderItem =>
          manufacturingOrderItem.isSelected &&
          manufacturingOrderItem.product &&
          !manufacturingOrderItem.details.addedToManufacturingOrder,
      )
      .map(({ isSelected, ...restOfManufacturingOrderItem }) => restOfManufacturingOrderItem);

    postCreateManufacturingOrderFromItems.mutate(manufacturingOrderItemsDTO, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  const columns = useExternalManufacturingOrdersColumns();

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Tworzenie zlecenia do producenta
        </Typography>
      }
      width={1400}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values, errors }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <FiltersSection filters={filters} setFilter={setFilter} />
            <div
              className={cx(
                styles.createManufacturingModalBody,
                "p-3 d-flex flex-column flex-1 overflow-hidden",
              )}
            >
              <Table<FormValues[number]>
                rows={getFilteredRows(filters, values)}
                columns={columns}
                isLoading={false}
                error={null}
                uiSchema={{
                  cell: { height: "60" },
                  header: { backgroundColor: "neutralWhite100" },
                }}
                overrides={() => {
                  return {
                    row: row => {
                      if (row.details.addedToManufacturingOrder || !row.product) {
                        return {
                          className: styles.createManufacturingOrderOpacity,
                        };
                      }
                      return {};
                    },
                  };
                }}
              />
              <div>
                {Object.keys(errors).length > 0 && (
                  <div className={cx(localStyles.errorsBoard, "pt-2")}>
                    {Object.keys(errors)
                      .slice(0, 3)
                      .map((index: string, mapIndex) => (
                        <div className="d-flex align-items-center gap-1" key={index}>
                          {console.log({ index })}
                          <Typography fontSize="14" fontWeight="500" color="danger500">
                            Produkt
                          </Typography>
                          <Typography fontSize="14" fontWeight="700" color="danger500">
                            {getFilteredRows(filters, values)?.[Number(index)]?.product?.name}
                          </Typography>
                          <Typography fontSize="14" fontWeight="500" color="danger500">
                            nie ma producenta.
                          </Typography>
                          <Typography fontSize="14" fontWeight="500" color="danger500">
                            Zamówienie:
                          </Typography>
                          <Typography fontSize="14" fontWeight="700" color="danger500">
                            {getFilteredRows(filters, values)?.[Number(index)]?.order?.signature}
                          </Typography>
                          {mapIndex === 2 && Object.keys(errors).length - 3 > 0 && (
                            <Typography color="deepPurple400" fontSize="14" fontWeight="600">
                              + {Object.keys(errors).length - 3}{" "}
                              {pluralize.pl(Object.keys(errors).length - 3, {
                                other: "zamówień",
                                plural: "zamówienia",
                                singular: "zamówienie",
                              })}{" "}
                              bez producenta
                            </Typography>
                          )}
                        </div>
                      ))}
                  </div>
                )}
                <div className="d-flex align-items-center gap-2 pt-4">
                  <Button
                    className="text-uppercase"
                    onClick={close}
                    size="medium"
                    variant="transparent"
                  >
                    Anuluj
                  </Button>
                  <Button
                    className="text-uppercase"
                    disabled={
                      !Boolean(
                        values.filter(
                          manufacturingOrderItem =>
                            manufacturingOrderItem.isSelected &&
                            manufacturingOrderItem.product &&
                            !manufacturingOrderItem.details.addedToManufacturingOrder,
                        ).length,
                      )
                    }
                    isLoading={isSubmitting}
                    size="medium"
                    type="submit"
                    variant="deepPurple"
                  >
                    Utwórz zlecenie
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

const getFilteredRows = (filters: Filters, values: FormValues) => {
  return filters.productKind === ProductKind.UPHOLSTERY
    ? values.filter(value => value.productKind === ProductKind.UPHOLSTERY)
    : filters.productKind === ProductKind.BOX
    ? values.filter(value => value.productKind === ProductKind.BOX)
    : values;
};

export const useManufacturingOrderItemsSelectionLogic = () => {
  const context = useFormikContext<FormValues>();

  const areAllItemsPreviouslyAdded =
    context.values.filter(
      manufacturingOrderItem =>
        manufacturingOrderItem.isSelected &&
        manufacturingOrderItem.details.addedToManufacturingOrder,
    ).length === context.values.length;

  const areAllItemsSelected =
    context.values.filter(
      manufacturingOrderItem => !manufacturingOrderItem.details.addedToManufacturingOrder,
    ).length ===
      context.values.filter(
        manufacturingOrderItem =>
          manufacturingOrderItem.isSelected &&
          manufacturingOrderItem.product &&
          !manufacturingOrderItem.details.addedToManufacturingOrder,
      ).length &&
    context.values.filter(
      manufacturingOrderItem => !manufacturingOrderItem.details.addedToManufacturingOrder,
    ).length !== 0;

  const areSomeItemsSelected =
    context.values.filter(
      manufacturingOrderItem =>
        manufacturingOrderItem.isSelected &&
        manufacturingOrderItem.product &&
        !manufacturingOrderItem.details.addedToManufacturingOrder,
    ).length > 0 &&
    context.values.filter(
      manufacturingOrderItem =>
        manufacturingOrderItem.isSelected &&
        manufacturingOrderItem.product &&
        !manufacturingOrderItem.details.addedToManufacturingOrder,
    ).length !==
      context.values.filter(
        manufacturingOrderItem => !manufacturingOrderItem.details.addedToManufacturingOrder,
      ).length;

  const setAllItemsSelection = (): void => {
    if (
      context.values.filter(
        manufacturingOrderItem => !manufacturingOrderItem.details.addedToManufacturingOrder,
      ).length !== 0
    ) {
      context.setValues(
        context.values.map(manufacturingOrderItem => ({
          ...manufacturingOrderItem,
          isSelected:
            !manufacturingOrderItem.product ||
            manufacturingOrderItem.details.addedToManufacturingOrder
              ? false
              : !areAllItemsSelected,
        })),
      );
    }
  };

  return {
    areAllItemsPreviouslyAdded,
    areAllItemsSelected,
    areSomeItemsSelected,
    setAllItemsSelection,
  };
};
