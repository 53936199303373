import { RouteTrackingDay, TrackingStatus } from "api/call-center/routes/models";
import timelineIcon from "assets/images/timeline.svg";
import whiteFlagIcon from "assets/images/whiteFlag.svg";
import mailIcon from "assets/images/mail.svg";
import styles from "../RouteTrackingModal.module.css";
import cx from "classnames";
import { Button } from "components/common";
import { formatDeliveryDate } from "../utils/formatDeliveryDate";
import React from "react";
import { RoutePosition } from "./RoutePosition";
import { DayType, RouteTrackingFilters } from "../RouteTrackingModal";
import { MdiClearNight } from "components/miloDesignSystem/atoms/icons/MdiClearNight";

interface Props {
  className?: string;
  days: RouteTrackingDay[];
  dayType: DayType;
  filters: RouteTrackingFilters;
  routeTrackingStatus: TrackingStatus;
}

export const RoutePositionsList = ({
  className,
  days,
  dayType,
  filters,
  routeTrackingStatus,
}: Props) => {
  return (
    <>
      {dayType === "CURRENT" && days.length > 0 && (
        <div className={cx(styles.routeHeader, "px-2 mt-3")}>
          <span className="body-16">
            <img alt="" src={timelineIcon} />
            Dzisiejsza trasa
          </span>
        </div>
      )}
      {dayType === "FINISHED" && days.length > 0 && (
        <div className={cx(styles.routeHeader, "px-2 mt-3")}>
          <span className="body-16">
            <img alt="" src={whiteFlagIcon} />
            Zakończone
          </span>
        </div>
      )}
      <div
        className={cx(styles.listTable, className, {
          [styles.listDisabled]: dayType === "FINISHED",
        })}
      >
        {days.map(day => (
          <React.Fragment key={day.deliveryDate}>
            <div className="d-flex align-items-center justify-content-between gap-1 py-2 px-3">
              <h4 className={styles.deliveryDate}>
                {day.deliveryDate ? formatDeliveryDate(day.deliveryDate) : ""}
              </h4>
              <Button
                className={cx({
                  [styles.btnDisabled]:
                    dayType !== "CURRENT" || (dayType === "CURRENT" && day.positions.length === 0),
                })}
                disabled={dayType !== "CURRENT"}
                kind="create"
              >
                <div className="btnBase btnBaseSmall">
                  <img alt="" src={mailIcon} />
                  Wyślij SMS do wszystkich opóźnionych
                </div>
              </Button>
            </div>
            <div
              className={cx(styles.tableHeader, styles.tableHeaderTracking, styles.bgNone, "mx-2", {
                [styles.tableHeaderBorder]: day.positions.length > 0 && !day.positions[0].isCurrent,
              })}
            >
              <div>#</div>
              <div>adres</div>
              <div>zamówienie</div>
              <div>telefon</div>
              <div>dostawa (sz.)</div>
              <div>przedział</div>
              <div>przerwa</div>
              <div>dostawa (r.)</div>
              <div>opóźnienie (r.)</div>
              <div>przedział (nowy)</div>
              <div>opóźnienie (sz.)</div>
              <div>odległość</div>
              <div>SMS o opóźnieniu</div>
              <div>odp.</div>
              <div>doręczenie</div>
            </div>
            <div
              className={cx(styles.list, "w-100 px-2 pb-2", {
                [styles.listOverflowScroll]:
                  day.positions.length > 0 && !day.positions[0].isCurrent,
              })}
            >
              {day.positions.map((position, index) => (
                <RoutePosition
                  dayType={dayType}
                  filters={filters}
                  index={index}
                  key={position.id}
                  position={position}
                  positionsLength={day.positions.length}
                  routeTrackingStatus={routeTrackingStatus}
                />
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
      {dayType === "CURRENT" && days.length > 0 && days[0].positions.length > 0 && (
        <div className={cx(styles.nightStop, "px-2 mb-3")}>
          <span className="body-12">
            <MdiClearNight size="12" /> Postój dobowy
          </span>
        </div>
      )}
    </>
  );
};
